import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Head from "react-helmet"

const Helmet = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            steps_title
            steps_description
            steps_author
            steps_keywords
            steps_url
          }
        }
      }
    `
  )

  const meta = site.siteMetadata

  return (
    <Head>
      <title>{meta.steps_title}</title>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta http-equiv="X-UA-Compatible" content="ie=edge" />
      <html lang="en" />
      <meta name="description" content={meta.steps_description} />
      <meta name="keywords" content={meta.steps_keywords} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={meta.steps_title} />
      <meta property="og:url" content={meta.steps_url} />
      <meta property="business:contact_data:country_name" content="USA" />
      <meta property="og:description" content={meta.steps_description} />
    </Head>
  )
}

export default Helmet
