import React from "react"
import { motion } from "framer-motion"
import { Paragraph } from "_data/global_styles"
import Button from "_components/buttons/button_parallax"
import PageHeadline from "_components/misc/pageHeadline"
import styled from "styled-components"
import {
  color_paragraph,
  color_primButton,
} from "_src/assets/data/global_colors"

const Copy = (props) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <motion.div
        initial={{ y: 30, opacity: 0, rotate: 0.01 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.25, duration: 0.85, ease: "easeOut" }}
      >
        <motion.div style={{ x: props.titleX, y: props.titleY }}>
          <PageHeadline copy="Get started in three steps..." />
        </motion.div>
      </motion.div>
      <motion.div style={{ marginLeft: ".5vw", color: "#303c6c" }}>
        <motion.div style={{ x: props.paraX, y: props.paraY }}>
          <Step
            initial={{ y: 35, opacity: 0, rotate: 0.01 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.35, duration: 0.85, ease: "easeOut" }}
          >
            <StepNum>Step 1:</StepNum>
            <Paragraph style={{ color: color_paragraph }}>
              Click wherever you see this button:
            </Paragraph>
            <Button
              color={color_primButton}
              shadowColor="black"
              copy="Enter Session"
              destination="/session"
              height={60}
              width={180}
              noParallax={true}
            />
          </Step>
          <Step
            initial={{ y: 35, opacity: 0, rotate: 0.01 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.85, ease: "easeOut" }}
          >
            <StepNum style={{ marginTop: "3vh" }}>Step 2:</StepNum>
            <Paragraph style={{ marginTop: "0vh", color: color_paragraph }}>
              Enter a unique "Session Password" that you will share with your
              client. Session Passwords can be words, numbers, or even phrases.
              Spelling and punctuation count!
            </Paragraph>
          </Step>
          <Step
            initial={{ y: 35, opacity: 0, rotate: 0.01 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.75, duration: 0.85, ease: "easeOut" }}
          >
            <StepNum style={{ marginTop: "3vh" }}>Step 3:</StepNum>
            <Paragraph style={{ color: color_paragraph }}>
              Connect to one of the video conferencing options and begin the BLS processing.
            </Paragraph>
          </Step>
        </motion.div>
      </motion.div>
    </div>
  )
}

const Step = styled(motion.div)`
  /* margin-left: 0.25vw; */
`

const StepNum = styled.div`
  font-family: montserrat;
  font-weight: 800;
  color: orange;
  font-size: 1em;
`

export default Copy
