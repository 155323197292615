import React, { useEffect } from "react"
import { color_menuText } from "_src/assets/data/global_colors"
import { SiYoutube } from "react-icons/si"
import { motion, useSpring, useTransform } from "framer-motion"
import { useMitt } from "react-mitt"
import Copy from "./steps_content-copy"
import styled from "styled-components"

const StepsContent = () => {
  const { emitter } = useMitt()
  let mouseX = useSpring(0, { restDelta: 0.001, restSpeed: 0.001 })
  let mouseY = useSpring(0, { restDelta: 0.001, restSpeed: 0.001 })
  let titleX = useTransform(mouseX, (value) => value / 340)
  let titleY = useTransform(mouseY, (value) => value / 320)
  let paraX = useTransform(mouseX, (value) => value / 520)
  let paraY = useTransform(mouseY, (value) => value / 580)
  let videoX = useTransform(mouseX, (value) => value / 720)
  let videoY = useTransform(mouseY, (value) => value / 780)

  useEffect(() => {
    emitter.on("mouseX", (event) => {
      const offsetX = event.data - window.innerWidth / 2
      mouseX.set(offsetX)
    })

    emitter.on("mouseY", (event) => {
      const offsetY = event.data - window.innerHeight / 2
      mouseY.set(offsetY)
    })
  }, [emitter, mouseX, mouseY])

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Content>
        <VideoWrapper style={{ x: videoX, y: videoY }}>
          <Video
            initial={{ y: 20, opacity: 0, rotate: 0.01 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.85, ease: "easeOut" }}
          >
            <iframe
              title="Vid"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/74UvWk39_Zo"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Video>
          <a
            href="https://www.youtube.com/channel/UCcbAmJDgC_GxU0Pombs1iNA"
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: color_menuText, textDecoration: "none" }}
          >
            <Caption
              initial={{ opacity: 0, rotate: 0.01 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1, duration: 0.85 }}
            >
              <span
                style={{
                  fontSize: "2em",
                  verticalAlign: "middle",
                  marginRight: "5px",
                  color: "#e91d00",
                }}
              >
                <SiYoutube />
              </span>
              See our YouTube channel for more videos.
            </Caption>
          </a>
        </VideoWrapper>
        <CopyWrapper>
          <Copy titleX={titleX} titleY={titleY} paraX={paraX} paraY={paraY} />
        </CopyWrapper>
      </Content>
    </div>
  )
}

const Content = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  max-width: 1000px;
  @media only screen and (max-width: 1600px) {
    width: 80%;
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    width: 75%;
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 650px) {
    width: 90%;
  }
`

const VideoWrapper = styled(motion.div)`
  order: 1;
  height: 211px;
  width: 375px;
  @media only screen and (max-width: 1000px) {
    width: 250px;
    height: 140px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 5vh;
    order: 2;
    width: 70vw;
    height: 38vw;
  }
`

const Video = styled(motion.div)`
  position: relative;
  z-index: 10000;
  width: 100%;
  height: 100%;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.65),
    0 10px 10px -5px rgba(50, 50, 73, 0.5);
`

const Caption = styled(motion.div)`
  padding-top: 20px;
  cursor: pointer;
  text-align: center;
  font-family: montserrat;
  font-size: 0.7em;
  line-height: 0.5em;
  font-weight: 700;
`

const CopyWrapper = styled.div`
  order: 2;
  position: relative;
  z-index: 3;
  flex: 1 1 auto;
  width: 50%;
  margin: 0 0 0 30px;
  @media only screen and (max-width: 900px) {
    margin: 0;
    order: 1;
    width: 100%;
  }
`

export default StepsContent
