import { useEffect } from "react"

export function CheckLocation(
  location,
  setShowFooter,
  setNextPageAllowed,
  setPreviousPageAllowed
) {
  useEffect(() => {
    if (location.state) {
      if (location.state.showFooter) {
        setShowFooter(true)
        setNextPageAllowed(false)
        setPreviousPageAllowed(false)
      }
    } else {
      setShowFooter(true)
      setNextPageAllowed(false)
      setPreviousPageAllowed(false)
    }
  }, [setShowFooter, setNextPageAllowed, setPreviousPageAllowed, location])
}
