import React, { useState } from "react"
import "_data/normalize.css"
import { ScreensLayout } from "_components/indexScreens/common/screensLayout"
import Content from "_components/indexScreens/_steps/steps_content"
import Seo from "_components/indexScreens/_steps/steps_seo"
import { CheckLocation } from "_components/indexScreens/common/CheckLocation"

const currentScreen = 3
const initialUpperContentMargin = 120
const initialLowerContentMargin = 230
const screenBackgroundColor = "#f4f4f4"
const screenSwipeBackgroundColor = "white"

const Steps = ({ location }) => {
  const [showFooter, setShowFooter] = useState(false)
  const [previousPageAllowed, setPreviousPageAllowed] = useState(true)
  const [nextPageAllowed, setNextPageAllowed] = useState(true)

  CheckLocation(
    location,
    setShowFooter,
    setNextPageAllowed,
    setPreviousPageAllowed
  )

  return (
    <>
      <Seo />
      {ScreensLayout(
        Content,
        screenSwipeBackgroundColor,
        screenBackgroundColor,
        initialUpperContentMargin,
        initialLowerContentMargin,
        showFooter,
        nextPageAllowed,
        previousPageAllowed,
        currentScreen
      )}
    </>
  )
}

export default Steps
